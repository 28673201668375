
import * as __star__ from '@clayui/nav';

const {
default: __default__,
ClayVerticalNav,
__esModule
} = __star__;

export {
ClayVerticalNav,
__esModule
};
export default __default__;
